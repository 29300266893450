import React from 'react'
import { graphql } from 'gatsby'
import { Row } from 'reactstrap'
import cheerio from 'cheerio'
import classNames from 'classnames'
import PageHelmet from '../components/PageHelmet'
import SubCategoryArticles from '../components/SubCategoryArticles'
import wordLinks from '../utils/wordLinks'
import SidebarLinksForInterviewAdvice from '../components/SidebarLinksForInterviewAdvise'
import SidebarLinks from '../components/SidebarLinks'
import styles from './content.module.scss'

const SubCategoryTemplate = ({
  data: { subcategory, articles },
  location,
  pageContext,
}) => {
  const { words } = pageContext
  const htmlBody =
    subcategory.body && wordLinks(cheerio.load(subcategory.body), words)

  return (
    <>
      <PageHelmet
        {...{
          title: subcategory.metaTitle || subcategory.title,
          description:
            subcategory.metaDescription && subcategory.metaDescription,
          url: `https://www.wikijob.co.uk${location.pathname}`,
        }}
      />
      <Row>
        <div className="content col-lg-8">
          <a
            href={`/content/${subcategory.category.slug}`}
            itemProp="item"
            className={`${styles.goBackLink} orange-link`}
          >
            <span itemProp="name">
              {`Back to ${subcategory.category.title}`}
            </span>
            <meta itemProp="position" content="2" />
          </a>

          <h1 className="main-heading" style={{ paddingTop: '0' }}>
            {subcategory.title}
          </h1>
          <div className="under-heading-line" />

          {articles.edges.length > 0 && (
            <SubCategoryArticles articles={articles.edges} />
          )}
          {htmlBody && (
            <div
              className={classNames(styles.content, 'mediavineAdsInContent')} // TODO: if fixed class for Mediavine, don't change
              dangerouslySetInnerHTML={{ __html: htmlBody }}
            />
          )}
        </div>
        <div className="col-lg-4">
          <div style={{ marginTop: '182px' }}>
            {location.pathname.match('interview-advice') && (
              <SidebarLinksForInterviewAdvice />
            )}
            {location.pathname.match('aptitude-test') && <SidebarLinks />}
          </div>
        </div>
      </Row>
    </>
  )
}

export const query = graphql`
  query($id: String!) {
    subcategory: datoCmsSubcategory(id: { eq: $id }) {
      id
      title
      slug
      metaTitle
      metaDescription
      body
      category {
        id
        title
        slug
      }
    }
    articles: allDatoCmsArticle(
      limit: 100
      filter: { category: { id: { eq: $id } } }
    ) {
      edges {
        node {
          id
          title
          slug
          shortUrl
          category {
            id
            title
            slug
            category {
              id
              title
              slug
            }
          }
          coverImage {
            fluid(
              imgixParams: {
                auto: "compress, format, enhance"
                fit: "fill"
                w: "356"
                h: "143"
              }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`

export default SubCategoryTemplate
