import React from 'react'
import { Link } from 'gatsby'
import { Row, Col } from 'reactstrap'
import articleUrl from 'src/utils/articleUrl'
import Img from 'gatsby-image'
import styles from './subcategoryArticles.module.scss'

const fallbackImage =
  'https://www.datocms-assets.com/7756/1600624248-wikijob-large-2.png?w=220,auto=format,compress'

const formatYear = (s) => s.replace('{YEAR}', new Date().getUTCFullYear())

const byTitleAsc = (a, b) =>
  a.node.title > b.node.title ? 1 : b.node.title > a.node.title ? -1 : 0

const itemImage = (coverImage) =>
  (coverImage ? (
    <Img fluid={coverImage.fluid} className={styles.articleCoverImage} />
  ) : (
    <div
      className={styles.articleCoverImage}
      style={{
        backgroundImage: `url(${fallbackImage})`,
      }}
    />
  ))

const SubCategoryArticles = ({ articles }) => (
  <Row className={styles.subcategoriesRow}>
    {articles.sort(byTitleAsc).map(({ node: article }) =>
      article.slug === '_' ? null : (
        <Col
          xs={12}
          md={6}
          lg={4}
          key={article.id}
          className={styles.subcategoryColumn}
        >
          <Link className={styles.articleLink} to={articleUrl(article)}>
            {itemImage(article.coverImage)}
            <h5 className={styles.articleTitle}>{formatYear(article.title)}</h5>
          </Link>
        </Col>
      ),
    )}
  </Row>
)

export default SubCategoryArticles
