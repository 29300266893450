import React from 'react'
import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import styles from './BlockInterviewPerformance.module.scss'

const SidebarLinks = () => {
  const data = useStaticQuery(graphql`
    {
      sidebarLinks: allDatoCmsSidebar(
        filter: { thePageWantsToDisplay: { eq: "anywhere" } }
      ) {
        edges {
          node {
            id
            text
            url
            thePageWantsToDisplay
          }
        }
      }
    }
  `)

  return (
    <div className="w-100">
      <div className={classNames(styles.sidebar, 'shadow-sm')}>
        <div className={styles.sidebarHeader}>
          Improve Aptitude Test Performance
        </div>
        <div className={styles.sidebarListGroup}>
          {data.sidebarLinks.edges.map(({ node: { url, id, text } }) => (
            <a
              {...{
                className: classNames(
                  styles.sidebarListGroupItem,
                  'blockInterviewPerformance',
                ),
                target: '_blank',
                rel: 'nofollow noopener noreferrer sponsored',
                href: url,
                key: id,
              }}
            >
              {text}
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

export default SidebarLinks
